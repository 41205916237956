<template>
  <v-container fluid>
    <v-card elevation="1">
      <v-card-text>
        <v-row justify="space-between">
          <v-col cols="auto" class="py-1">
            <span class="subtitle-1"
              >Withdraw <span v-if="!date">Recent</span> Requests
              <span v-if="!recordsLoading"
                >({{ parseInt(records.length).toLocaleString() }})</span
              ></span
            >
          </v-col>
          <v-col cols="auto" class="text-right py-1">
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn small outlined color="primary" v-bind="attrs" v-on="on">
                  <v-icon small class="me-2">
                    mdi-calendar-text-outline
                  </v-icon>
                  {{ dateFormatted }}
                </v-btn>
              </template>
              <v-date-picker v-model="date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text @click="modal = false"> Cancel </v-btn>
                <v-btn text @click="filterDate"> Ok </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-3">
      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="records"
          :items-per-page="itemPerPage"
          :loading="!accountStatus || recordsLoading"
          loading-text="Loading, please wait"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:item.status="{ item }">
            <div v-if="$vuetify.breakpoint.smAndUp">
              <v-chip
                small
                class="font-weight-bold"
                :class="
                  item.status == 'Valid'
                    ? 'green lighten-5 green--text'
                    : item.status == 'Invalid'
                    ? 'red lighten-5 red--text'
                    : 'blue lighten-5 blue--text'
                "
              >
                {{ item.status }}
              </v-chip>
            </div>
            <div v-else>
              <span
                :class="
                  item.status == 'Valid'
                    ? 'green--text'
                    : item.status == 'Invalid'
                    ? 'red--text'
                    : 'blue--text'
                "
              >
                {{ item.status }}
              </span>
            </div>
          </template>

          <template v-slot:item.points="{ item }">
            {{ moneyFormat(parseFloat(item.points)) }}
          </template>

          <template v-slot:item.date="{ item }">
            <span>{{ formatTheDate(item.date) }}</span>
          </template>

          <template v-slot:item.action="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  @click="viewDetails(item)"
                  class="d-none d-sm-inline blue lighten-5 primary--text"
                >
                  <v-icon> mdi-card-search-outline </v-icon>
                </v-btn>
              </template>
              <span>View Details</span>
            </v-tooltip>

            <v-btn
              outlined
              small
              color="primary"
              @click="viewDetails(item)"
              class="d-inline d-sm-none"
            >
              View Details
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-btn
          icon
          @click="dialog = false"
          absolute
          right
          style="top: 10px; right: 5px"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="py-3 px-4"> Withdraw Details </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-4 px-3">
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>Status</th>
                  <td>
                    <v-chip
                      small
                      class="font-weight-bold"
                      :class="
                        viewData.status == 'Valid'
                          ? 'green lighten-5 green--text'
                          : viewData.status == 'Invalid'
                          ? 'red lighten-5 red--text'
                          : 'blue lighten-5 blue--text'
                      "
                    >
                      {{ viewData.status }}
                    </v-chip>
                  </td>
                </tr>
                <tr>
                  <th>Token</th>
                  <td>{{ moneyFormat(parseFloat(viewData.points)) }}</td>
                </tr>
                <tr v-if="viewData.bank">
                  <th>Bank</th>
                  <td>{{ viewData.bank }}</td>
                </tr>
                <tr v-if="viewData.account_name">
                  <th>Account Name</th>
                  <td>{{ viewData.account_name }}</td>
                </tr>
                <tr v-if="viewData.account_number">
                  <th>Account Number</th>
                  <td>{{ viewData.account_number }}</td>
                </tr>
                <tr>
                  <th>Requested Date</th>
                  <td>{{ formatTheDate(viewData.date) }}</td>
                </tr>
                <tr>
                  <th>Evaluated Date</th>
                  <td>{{ formatTheDate(viewData.evaluated_at) }}</td>
                </tr>
                <tr v-if="viewData.remarks">
                  <th>Remarks</th>
                  <td>{{ viewData.remarks }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import formatDate from "@/helpers/formatDate";
import { mapState, mapActions } from "vuex";

export default {
  data: () => ({
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date: "",
    menu: false,
    modal: false,
    sortBy: "date",
    sortDesc: true,
    headers: [
      { text: "Token", value: "points", class: "text-uppercase" },
      { text: "Bank", value: "bank", class: "text-uppercase" },
      { text: "Requested Date", value: "date", class: "text-uppercase" },
      { text: "Status", value: "status", class: "text-uppercase" },
      {
        text: "Action",
        value: "action",
        sortable: false,
        class: "text-uppercase",
      },
    ],
    dialog: false,
    viewData: null,
  }),
  created() {
    this.$store.commit("withdraw/setRecords", []);

    this.loadRecords();
  },
  computed: {
    ...mapState("withdraw", ["records", "recordsLoading"]),
    ...mapState(["accountStatus"]),
    dateFormatted() {
      if (!this.date) {
        return "Filter";
      } else {
        if (this.date == this.today) {
          return "Today";
        } else {
          return formatDate(this.date, "date");
        }
      }
    },
    itemPerPage() {
      if (!this.date) {
        return -1;
      } else {
        return 10;
      }
    },
  },
  methods: {
    ...mapActions("withdraw", ["getRecords"]),
    formatTheDate(theDate) {
      return formatDate(theDate);
    },
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    filterDate() {
      this.$refs.dialog.save(this.date);

      this.loadRecords();
    },
    async loadRecords() {
      if (!this.recordsLoading && this.accountStatus == "Verified") {
        const formData = new FormData();
        formData.append("date_start", this.date);

        const getData = new URLSearchParams(formData);

        await this.getRecords(getData);
      }
    },
    viewDetails(item) {
      this.viewData = item;
      this.dialog = true;
    },
  },
  watch: {
    accountStatus(val) {
      if (val) {
        this.loadRecords();
      }
    },
  },
};
</script>
